<template>
    <!-- 弹框 -->
    <el-dialog title="新增" :visible.sync="show" :before-close="onCancel">
        <el-form>

        </el-form>
        <div class="search">
            <div>
                <el-cascader :options="options" placeholder="筛选分类" v-model="categoryidarr" @change="clearlist" @visible-change="changeid" :props="props" collapse-tags clearable style="margin-right: 20px;"></el-cascader>
                <el-input v-model="productName" placeholder="请输入商品名称" suffix-icon="el-icon-search" @change="onSearch" clearable style="margin-right: 20px;">
                </el-input>
                <el-button type="primary" @click="changeall">全 选</el-button>

            </div>
            <div>
                <!-- <el-cascader :options="options" v-model="Allcategoryidarr" :props="props" @visible-change="changeall" collapse-tags clearable></el-cascader> -->
            </div>

        </div>
        <div class="globle_table">
            <el-table :data="tableData" style="width: 100%" ref="multipleTable" tooltip-effect="dark" row-key="productSupplierId" @select-all="selectAll" @select="handleSelectionChange">
                <el-table-column type="selection" width="55">
                </el-table-column>
                <el-table-column prop="serialId" label="商品编号" width="180">
                </el-table-column>
                <el-table-column prop="productName" label="商品名称" width="180">
                </el-table-column>
                <el-table-column prop="brand" label="品牌" width="180">
                </el-table-column>
                <el-table-column prop="factoryName" label="厂家" width="180">
                </el-table-column>
                <el-table-column prop="categoryName" label="分类名称" width="180">
                </el-table-column>
                <el-table-column prop="specs" label="规格 " width="180">
                </el-table-column>
            </el-table>
        </div>
        <div class="pagebox">
            <el-pagination background @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper,sizes, total" :total="totalItemsCount">
            </el-pagination>
        </div>
        <span slot="footer">
            <el-button @click="onCancel">取 消</el-button>
            <el-button type="primary" :disabled='btdisabled' @click="onSubmit">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        show(val) {
            if (val) {
                this.Selectlist = this.productalllist
                    ? this.productalllist
                    : [];
                this.productList();
            }
        },
    },
    data() {
        return {
            currentPage: 1, // 当前页码
            pageSize: 10, // 每页显示的行数
            pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
            totalItemsCount: 0,
            categoryName: "",
            categoryId: "",
            categoryidarr: [],
            Allcategoryidarr: [],
            tableData: [],
            productName: "",
            productalllist: [],
            props: {
                checkStrictly: true,
                value: "categoryId",
                label: "categoryName",
                children: "children",
            },
            options: [],
            Selectlist: [],
            btdisabled:false
        };
    },
    created() {
        this.getcategoryList();
    },
    mounted() {},
    methods: {
        handleSelectionChange(arr, row) {
            const bool = this.Selectlist.some(
                (v) => v.productSupplierId === row.productSupplierId
            );
            if (bool) {
                this.Selectlist = this.Selectlist.filter(
                    (v) => v.productSupplierId !== row.productSupplierId
                );
            } else {
                this.Selectlist.push(row);
            }
        },
        selectAll(arr) {
            if (arr.length !== 0) {
                arr.forEach((item) => {
                    const bool = this.Selectlist.some(
                        (v) => v.productSupplierId === item.productSupplierId
                    );
                    if (!bool) {
                        this.Selectlist.push(item);
                    }
                });
            } else {
                this.tableData.forEach((item) => {
                    this.Selectlist = this.Selectlist.filter(
                        (v) => v.productSupplierId !== item.productSupplierId
                    );
                });
            }
        },
        echoSelected() {
            // 回显选中
            if (this.Selectlist.length > 0) {
                this.$nextTick(() => {
                    this.tableData.forEach((item) => {
                        if (
                            this.Selectlist.some(
                                (v) =>
                                    v.productSupplierId ===
                                    item.productSupplierId
                            )
                        ) {
                            // 存在添加
                            this.$refs.multipleTable.toggleRowSelection(item);
                        }
                    });
                });
            }
        },
        getcategoryList() {
            this.$axios
                .get(this.$api.categoryList, {
                    params: {
                        page: 1,
                        pageSize: 9999,
                        auditStatus: 1,
                        categoryName: this.categoryName,
                    },
                })
                .then((res) => {
                    this.options = res.data.result.list;
                });
        },
        changeid(value) {
            if (!value) {
                this.categoryId =
                    this.categoryidarr.length != 0
                        ? this.categoryidarr[this.categoryidarr.length - 1]
                        : "";
                this.currentPage = 1;
                this.productList();
            }
        },
        clearlist(value) {
            this.currentPage = 1;
            this.categoryId = value.length != 0 ? value[value.length - 1] : "";
            if (this.categoryId == "") {
                this.productList();
                return;
            }
        },
        changeall() {
           var loading= this.$loading({
                lock: true,
                text: "加载中",
                spinner: "el-icon-loading",
                background: "rgba(255, 255, 255, 0.7)",
            });
            // var categoryId =
            //     this.Allcategoryidarr.length != 0
            //         ? this.Allcategoryidarr[
            //               this.Allcategoryidarr.length - 1
            //           ]
            //         : "";
            // if (categoryId == "") {
            //     this.productList();
            //     return;
            // }
            this.$axios
                .get(this.$api.producttendersList2, {
                    params: {
                        categoryId: this.categoryId,
                    },
                })
                .then((res) => {
                    this.echoSelected(); //重置选中
                    setTimeout(() => {
                        var storelist = this.Selectlist;
                        var pushlist = storelist.concat(res.data.result);
                        let uniqueDataList = pushlist.filter(
                            (item, index, self) => {
                                const firstIndex = self.findIndex(
                                    (obj) =>
                                        obj.productSupplierId ===
                                        item.productSupplierId
                                );
                                return index === firstIndex;
                            }
                        );
                        this.Selectlist = uniqueDataList;
                        this.echoSelected();
                         loading.close()
                         this.$forceUpdate()
                    }, 100);
                });
                
                
        },
        productList() {
            this.$axios
                .get(this.$api.producttendersList, {
                    params: {
                        page: this.currentPage,
                        pageSize: this.pageSize,
                        productName: this.productName,
                        auditStatus: 1,
                        categoryId: this.categoryId,
                    },
                })
                .then((res) => {
                    this.tableData = res.data.result.list;
                    this.totalItemsCount = res.data.result.totalCount;
                    this.echoSelected();
                });
        },
        handleCurrentChange(page) {
            this.currentPage = page;
            this.productList();
        },
        handleSizeChange(pageSize) {
            this.pageSize = pageSize;
            this.productList();
        },
        onSearch() {
            this.currentPage = 1;
            this.productList();
        },
        onCancel() {
            this.Selectlist = [];
            this.echoSelected();
            this.$emit("onCancel");
        },
        onSubmit() {
         var loading =  this.$loading({
                lock: true,
                text: "加载中",
                spinner: "el-icon-loading",
                background: "rgba(255, 255, 255, 0.7)",
            });
            this.btdisabled = true
            this.Selectlist = this.Selectlist.map((v) => {
                return {
                    ...v,
                    depositAmount: v.depositAmount ? v.depositAmount : 0,
                };
            });
           setTimeout(()=>{
             this.btdisabled = false
              loading.close()
              this.$emit("onSubmit", this.Selectlist);
           },1000)
          
        },
        handleDel() {},
    },
};
</script>

<style lang="scss" scoped>
.pagebox {
    ::v-deep .el-input,
    ::v-deep .el-input__inner {
        width: 90px;
    }
}

.search {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;

    ::v-deep .el-input {
        width: 255px;
    }
}
</style>