<template>
  <div class="user">
    <back></back>
    <div class="globle_border">
      <!-- 基本信息 -->
      <div v-show="isShow">
        <h3>标书信息</h3>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="180px" class="demo-ruleForm">
          <el-row>
            <el-col :span="12">
              <el-form-item label="标期" prop="period">
                <el-input v-model="ruleForm.period" placeholder="请输入标期"></el-input>
              </el-form-item>
              <el-form-item label="标号" prop="tendersNumber">
                <el-input controls v-model.number="ruleForm.tendersNumber" placeholder="请输入标号"></el-input>
              </el-form-item>
              <el-form-item label="开标时间" prop="tendersTime">
                <el-date-picker type="date" placeholder="选择日期" v-model="ruleForm.tendersTime"></el-date-picker>
              </el-form-item>
              <el-form-item label="应标资格类型" prop="radio">
                <el-radio-group v-model="ruleForm.radio">
                  <el-radio :label="1">平台资格</el-radio>
                  <el-radio :label="2">独立标资格</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="标书名称" prop="tendersName">
                <el-input v-model="ruleForm.tendersName" placeholder="请输入标书名称"></el-input>
              </el-form-item>
              <el-form-item label="招标类型" prop="tendersType">
                <el-select v-model="ruleForm.tendersType" placeholder="请选择招标类型">
                  <el-option label="商超" value="1"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="中标有效时间" prop="dateRange2">
                <el-date-picker type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" v-model="ruleForm.dateRange2" :picker-options="pickerOptions4"></el-date-picker>
              </el-form-item>
              <el-form-item v-if="ruleForm.radio == 2" label="应标资格金额" prop="dateRange2">
                <el-input-number v-model="ruleForm.bidNumber" @change="handleNumber" :precision="2" :min="1" label="请输入缴费金额"></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="23">
              <el-form-item label="招标公告">
                <VueEditor :config="config" ref="vueEditor" v-model="content" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div style="text-align: center">
          <!-- <el-button @click="onCancel">取 消</el-button> -->
          <el-button type="primary" @click="submitForm('ruleForm')">下一步</el-button>
        </div>
      </div>
      <!-- 表格 -->
      <div v-show="!isShow">
        <h1>新增标书</h1>
        <div style="display: flex; justify-content: space-between; margin: 20px 0px">
          <div style="display: flex; align-items: center">
            <div style="font-size: 16px; margin-right: 30px">
              <span style="color: red">*</span>
              投标数量限制
            </div>
            <el-input-number v-model="bidNumber" @change="handleNumber" :precision="0" :min="0" label="描述文字"></el-input-number>
          </div>
          <el-button type="primary" @click="add" :disabled="disabled">新增</el-button>
        </div>
        <div class="globle_table">
          <el-table :data="tableData" style="width: 100%">
            <el-table-column label="序列" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                {{ scope.$index + 1 }}
                <!-- 索引号 -->
              </template>
            </el-table-column>
            <el-table-column prop="date" label="日期" width="180"></el-table-column>
            <el-table-column prop="name" label="姓名" width="180"></el-table-column>
            <el-table-column prop="address" label="地址"></el-table-column>
            <el-table-column prop="address" label="地址">
              <template slot-scope="scope">
                <el-input-number v-model="num" @change="handleChange(scope.row)" :precision="2" :step="0.1" label="请输入价格"></el-input-number>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount"></el-pagination>
      </div>
    </div>
    <!-- 弹框 -->
    <Dialog :show="dialogVisible" @onCancel="dialogVisible = false" @onSubmit="handleSubmit"></Dialog>
  </div>
</template>

<script>
import VueEditor from "vue-word-editor"
import Dialog from "@/components/bidDialog.vue"
import "quill/dist/quill.snow.css"

export default {
  components: {
    VueEditor,
    Dialog,
  },
  data() {
    return {
      content: "",
      radio: 1,
      disabled: true,
      num: 1,
      bidNumber: 0,
      disabledNum: false,
      isShow: true,
      loading: false,
      submitLoding: false,
      tendersName: "", //标书名称
      tendersNumber: "", //标号
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
      dialogVisible: false,
      isAdd: true,
      ruleForm: {
        tendersNumber: "", //标号
        period: "", //标期
        radio: "", //应标资格类型
        tendersType: "", //招标类型
        tendersName: "", //标书
        bidNumber: 0, //应标资格金额
        effectiveEndTime: "",
        payEndTime: "",
        effectiveStartTime: "",
        tendersTime: "",
        payMoney: "",
        applicationStartTime: "",
        tendersNotice: "",
        dateRange1: "",
        dateRange2: "",
      },

      pickerOptions3: {
        disabledDate: time => {
          const now = new Date()
          return time.getTime() < now.getTime()
        },
      },
      // 开标时间>中标时间
      pickerOptions4: {
        disabledDate: time => {
          // 当dateRange1还未选择时，可以禁用所有未来日期
          if (!this.ruleForm.tendersTime) {
            return true
          } else {
            return time.getTime() < this.ruleForm.tendersTime.getTime()
          }
        },
      },
      rules: {
        tendersNumber: [{ required: true, message: "请输入姓名", trigger: "submit" }],
        radio: [{ required: true, message: "请选择应标资格类型", trigger: "submit" }],
        period: [{ required: true, message: "请输入标期", trigger: "submit" }],
        tendersType: [{ required: true, message: "请选择招标类型", trigger: "submit" }],
        tendersName: [{ required: true, message: "请输入标书名称", trigger: "submit" }],
        bidNumber: [{ required: true, message: "请输入金额", trigger: "submit" }],
        applicationEndTime: [{ required: true, message: "请选择日期", trigger: "submit" }],
        applicationStartTime: [{ required: true, message: "请选择日期", trigger: "submit" }],
        payEndTime: [{ required: true, message: "请选择日期", trigger: "submit" }],
        effectiveStartTime: [{ required: true, message: "请选择日期", trigger: "submit" }],
        tendersTime: [{ required: true, message: "请选择日期", trigger: "submit" }],
        dateRange1: [{ required: true, message: "请选择日期", trigger: "submit" }],
        dateRange2: [{ required: true, message: "请选择日期", trigger: "submit" }],
        payMoney: [{ required: true, message: "请输入缴费金额", trigger: "submit" }],
      },
      config: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote", "code-block"],
            ["link", "image"],
            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
            [{ script: "sub" }, { script: "super" }], // superscript/subscript
            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
            [{ direction: "rtl" }], // text direction
          ],
        },
        // 上传图片的配置
        uploadImage: {
          url: this.$axios.defaults.baseURL + this.$api.uploadFile,
          name: "multipartFile",
          // res是结果，insert方法会把内容注入到编辑器中，res.data.url是资源地址
          uploadSuccess: (res, insert) => {
            if (res.data.code !== 100) {
              this.$message.error("请上传图片格式,如JPG,JPEG,PNG文件格式")
            } else {
              insert(res.data.result.fileUrl)
            }
          },

          showProgress: false,
        },
      },
      departmentList: [
        {
          departmentId: 1,
          departmentName: "商超",
        },
      ],
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄",
        },
      ],
      roleList: [],
      startStatus: "",
      options: [
        {
          value: "1",
          label: "未开始",
        },
        {
          value: "2",
          label: "进行中",
        },
        {
          value: "3",
          label: "已结束",
        },
      ],
    }
  },

  created() {
    // this.getportalPageList()
  },
  computed: {
    // rowstatus () {
    //   return function (row) {
    //     return this.options.find(e => e.value == row.startStatus).label
    //   }
    // },
  },
  methods: {
    // 触发清空时间
    onChangeDateRange1() {
      this.ruleForm = Object.assign({}, this.ruleForm, {
        dateRange2: "",
        payEndTime: "",
        tendersTime: "",
      })
    },
    onChangePayEndTime() {
      this.ruleForm = Object.assign({}, this.ruleForm, {
        dateRange2: "",
        tendersTime: "",
      })
    },
    onChangeTendersTime() {
      this.ruleForm = Object.assign({}, this.ruleForm, {
        dateRange2: "",
      })
    },

    handleInput(value) {
      // 限制金额最多两位小数
      this.ruleForm.payMoney = parseFloat(value).toFixed(2)
    },
    handleNumber() {
      if (this.bidNumber == 0) {
        this.disabled = true
      } else {
        this.disabled = false
      }
    },
    // 弹框
    add() {
      this.dialogVisible = true
    },
    // 弹框表格提交
    handleSubmit() {
      this.dialogVisible = false
    },
    // 数字
    handleChange(row) {
      console.log(row)
    },
    // 招标列表
    getportalPageList() {
      this.loading = true
      this.$axios
        .get(this.$api.portalPageList, {
          params: {
            page: this.currentPage,
            pageSize: this.pageSize,
            startStatus: this.startStatus,
            tendersName: this.tendersName,
            tendersNumber: this.tendersNumber,
          },
        })
        .then(res => {
          this.tableData = res.data.result.list
          this.totalItemsCount = res.data.result.totalCount
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },

    // 搜索
    onSearch() {
      // this.getportalPageList()
    },
    // 新增（对话框）
    handleAdd() {
      // this.isAdd = true
      // this.dialogVisible = true
      // this.ruleForm = {};
    },
    // 查看
    handleView(row) {
      this.$router.push({
        path: "/biddingManagement/detail/bidListDetails",
        query: { id: row.tendersId },
      })
    },
    // 修改（对话框）
    handleEdit(index, row) {
      this.isAdd = false
      this.dialogVisible = true
      this.ruleForm = Object.assign({}, row)
      if (!this.ruleForm.dateRange1) this.ruleForm.dateRange1 = []
      if (!this.ruleForm.dateRange2) this.ruleForm.dateRange2 = []
      // console.log(this.$dayjs(row.effectiveStartTime).toDate());
      // console.log(this.$dayjs(row.effectiveEndTime).toDate());
      this.ruleForm.dateRange1[0] = this.$dayjs(row.applicationStartTime).toDate() //报名开始时间
      this.ruleForm.dateRange1[1] = this.$dayjs(row.applicationEndTime).toDate() //报名结束时间
      this.ruleForm.dateRange2[0] = this.$dayjs(row.effectiveStartTime).toDate() //中标有效开始时间
      this.ruleForm.dateRange2[1] = this.$dayjs(row.effectiveEndTime).toDate()
      //中标有效结束时间
      this.$nextTick(() => {
        let quill = this.$refs.vueEditor.editor
        quill.clipboard.dangerouslyPasteHTML(row.tendersNotice)
      })
    },
    // 删除
    handleDelete(index, row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$axios
            .put(`${this.$api.tendersInfoDelete}/${row.tendersId}`)
            .then(res => {
              if (res.data.code == 100) {
                this.$message.success(res.data.desc)
              }
              this.getportalPageList()
            })
            .catch(() => {})
        })
        .catch(() => {})
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.pageSize = e
      // this.getportalPageList()
    },
    // 换页
    handleCurrentChange() {
      this.page += 1
      // this.getportalPageList()
    },
    // 关闭对话框
    onCancel() {
      this.dialogVisible = false
      this.$nextTick(() => {
        let quill = this.$refs.vueEditor.editor
        quill.clipboard.dangerouslyPasteHTML("")
      })
    },
    // 提交表单
    // submitForm(formName) {
    //   this.$refs[formName].validate((valid) => {
    //     if (!valid) {
    //       this.$message.error('提交失败');
    //       // return console.error('表单提交错误');
    //     }
    //     this.submitLoding = true
    //     let params = {
    //       applicationStartTime: this.$dayjs(this.ruleForm.dateRange1[0]).format('YYYY-MM-DD'),
    //       applicationEndTime: this.$dayjs(this.ruleForm.dateRange1[1]).format('YYYY-MM-DD'),
    //       payEndTime: this.$dayjs(this.ruleForm.payEndTime).format('YYYY-MM-DD'),
    //       tendersTime: this.$dayjs(this.ruleForm.tendersTime).format('YYYY-MM-DD'),
    //       effectiveStartTime: this.$dayjs(this.ruleForm.dateRange2[0]).format('YYYY-MM-DD'),
    //       effectiveEndTime: this.$dayjs(this.ruleForm.dateRange2[1]).format('YYYY-MM-DD'),
    //       tendersName: this.ruleForm.tendersName,
    //       tendersNumber: this.ruleForm.tendersNumber,
    //       tendersType: this.ruleForm.tendersType,
    //       payMoney: this.ruleForm.payMoney,
    //       tendersNotice: this.$refs.vueEditor.editor.root.innerHTML
    //     }
    //     let url = ''
    //     let method = ''
    //     // 根据对话框的状态来判断调起接口
    //     if (!this.isAdd) {
    //       params.userId = this.ruleForm.userId
    //       url = this.$api.tendersInfoUpdate
    //       method = 'put'
    //     } else {
    //       url = this.$api.tendersInfoAdd
    //       method = 'post'
    //     }
    //     this.$axios[method](url, params).then((res) => {
    //       if (res.data.code == 100) {
    //         this.$message.success(res.data.desc);
    //       }
    //       this.getportalPageList();
    //       this.submitLoding = false
    //       this.dialogVisible = false;
    //       this.resetForm();
    //     }).catch((err) => {
    //       this.submitLoding = false
    //       this.$message.error(err.data.desc);
    //     });
    //   });
    // },
    // 会话存储，下一步
    submitForm() {
      // this.$refs[formName].validate(() => {
      // if (!valid) {
      //   this.$message.error('提交失败');
      //   // return console.error('表单提交错误');
      // }
      // this.submitLoding = true
      let params = {
        // applicationStartTime: this.$dayjs(this.ruleForm.dateRange1[0]).format('YYYY-MM-DD'),
        // applicationEndTime: this.$dayjs(this.ruleForm.dateRange1[1]).format('YYYY-MM-DD'),
        // payEndTime: this.$dayjs(this.ruleForm.payEndTime).format('YYYY-MM-DD'),
        // tendersTime: this.$dayjs(this.ruleForm.tendersTime).format('YYYY-MM-DD'),
        effectiveStartTime: this.$dayjs(this.ruleForm.dateRange2[0]).format("YYYY-MM-DD"),
        effectiveEndTime: this.$dayjs(this.ruleForm.dateRange2[1]).format("YYYY-MM-DD"),
        tendersName: this.ruleForm.tendersName,
        tendersNumber: this.ruleForm.tendersNumber,
        tendersType: this.ruleForm.tendersType,
        // payMoney: this.ruleForm.payMoney,
        tendersNotice: this.$refs.vueEditor.editor.root.innerHTML,
      }
      console.log(params)

      this.isShow = false
      // let url = ''
      // let method = ''
      // 根据对话框的状态来判断调起接口

      // });
    },
    // 重置校验
    resetForm() {
      this.ruleForm = {}
      this.$refs.ruleForm.resetFields()
      this.ruleForm.tendersNotice = ""
      this.$nextTick(() => {
        let quill = this.$refs.vueEditor.editor
        quill.clipboard.dangerouslyPasteHTML("")
      })
    },
  },
}
</script>

<style scoped lang="scss">
.user {
  ::v-deep .ql-editor {
    min-height: 200px;
  }

  .el-date-editor ::v-deep .el-range-input {
    background: transparent;
  }

  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 200px;
      margin-left: 20px;
    }

    .el-button {
      margin-left: 20px;
    }
  }

  .el-input-number {
    width: 255px;
  }

  .table_list {
    width: 100%;
    max-width: 1560px;
    box-sizing: border-box;
    overflow-x: auto;

    .desc {
      display: -webkit-box;
      word-break: break-all;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .red {
      color: #eb2500;
    }

    .green {
      color: #c27c88;
    }

    .gray {
      color: #68706e;
    }

    .logo {
      display: block;
      width: 58px;
      height: 58px;
      border-radius: 4px;
    }

    .busilicense {
      display: block;
      width: 80px;
      height: 58px;
      border-radius: 4px;
    }

    ::v-deep .el-table__fixed-header-wrapper thead tr th {
      color: rgba(3, 16, 14, 0.9);
      background-color: #f4f6f9;
    }
  }
}
</style>
